import * as React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route } from 'react-router-dom';
import Home from "./pages/common/Home";
import About from "./pages/common/About";
import Down from "./pages/common/Down";

class App extends React.Component<{}, undefined> {
  public render() {
    return (
      <Router>
        <Route exact path="/" component={Home} />
        <Route exact path="/about" component={About} />
        <Route exact path="/down" component={Down} />
        <Route path="/app" component={props => <div />} />
      </Router>
    );
  }
}

const rootEl = document.getElementById("root");

render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  rootEl,
);
